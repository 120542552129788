import React from 'react';
import { graphql } from 'gatsby';

import { Layout, SEO } from 'src/components/shared';
import {
	JobsLayout,
	LayoutLeft,
	LayoutRight,
	JobBrief,
	JobMainImage
} from 'src/components/jobs';

export default function Template({ data: { markdownRemark, background } }) {
	const { frontmatter, html, excerpt } = markdownRemark;

	return (
		<Layout
			withLogo
			backgroundProps={{ fluid: background.childImageSharp.fluid }}
		>
			<SEO title={`Wanted: ${frontmatter.position}`} />

			<JobsLayout>
				<LayoutLeft>
					<JobMainImage
						fluid={frontmatter.thumbnail.childImageSharp.fluid}
					></JobMainImage>
				</LayoutLeft>
				<LayoutRight>
					<JobBrief
						frontmatter={frontmatter}
						html={html}
						excerpt={excerpt}
					></JobBrief>
				</LayoutRight>
			</JobsLayout>
		</Layout>
	);
}

export const pageQuery = graphql`
	query($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			excerpt(pruneLength: 640, format: HTML)
			id
			frontmatter {
				date(formatString: "DD/MM/YYYY")
				endDate(formatString: "DD/MM/YYYY")
				position
				salary
				location
				contract
				thumbnail {
					childImageSharp {
						fluid(quality: 90, maxWidth: 360) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		}
		background: file(relativePath: { eq: "background_jobs.png" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
